/** 
 * Die Klasse enthält Methoden die für die Preisdarstellung auf der Produktdetailseite verwendet werden.
*/
export class ProduktPrice {

    /**
     * Zeigt den passenden Beschreibungstext zu den ausgewählten Preis in der Mobile ansicht an
     * @param showPricedetailsDiv Das Element welches angeklickt wurde. In diesen Fall der Container des Buttons
     */
    ShowPriceDetailsMobileClicked(showPricedetailsDiv: HTMLElement): void {
        var priceId = $("#mobilePriceSelect").val();
        $(".mobilePriceDescription").hide();

        if ($(showPricedetailsDiv).hasClass("pricesHidden")) {
            $("div.mobilePriceDescription[data-id='" + priceId + "']").show();
            $(showPricedetailsDiv).removeClass("pricesHidden");
        } else {
            $(showPricedetailsDiv).addClass("pricesHidden");
        }

        $(showPricedetailsDiv).find("i").toggleClass("fa-angle-down fa-angle-up");
    }

    /**
     * Ist der Preisdetailtext ausgeklappt und der Preis wird geändert, wird der Text aktualisiert
     * @param selectedValue Die Id des neu ausgewählten Preises
     */
    UpdateDisplayedPriceDetailsMobile(selectedValue: string | number | string[]) {
        if (!$("div.priceDetailsMobile").hasClass("pricesHidden")) {
            $(".mobilePriceDescription").hide();
            $("div.mobilePriceDescription[data-id='" + selectedValue + "']").show();
        }
    }
}