import { Gesamtwerk } from './Models/Gesamtwerk';
import { AutorenInfo } from './Models/AutorenInfo';
import { RezensionenModel } from './Models/Rezensionen';
import 'bootstrap';

/**
 * Enthält Logiken die für das Rendern der Tabs zuständig sind
 */
export class TabsRenderer {
    currentProductId: any;

    constructor() {
        this.currentProductId = $('#currentProductId').val();
    }

    /**
     * Holt die Daten für das Gesamtwerk von der Api. Unterscheidet dabei, ob es ein Kopfprodukt ist oder nicht
     * Baut bei Success das Gesamtwerk-Tab zusammen.
     */
    getGesamtwerkTab() {
        var isPartOfSeries = $('#partOfSeries').val();
        var isHeadOfSeries = $('#headOfSeries').val();
        var seriesId = $('#seriesId').val();
        var kopfproduktId = $('#kopfproduktId').val();

        var apiUrl = isPartOfSeries
            ? '/Umbraco/api/ProduktdetailApi/GetGesamtwerkByPartOfSeriesId?id=' + seriesId
            : isHeadOfSeries
            ? '/Umbraco/api/ProduktdetailApi/GetGesamtwerkByHeadOfSeriesId?id=' + kopfproduktId
            : '';

        if (apiUrl !== '') {
            //die CurrentProductId braucht man, weil das aktuelle Produkt selbst nicht in der Gesamtwerk-Auflistung vorkommen soll.
            apiUrl += '&currentProductId=' + this.currentProductId;
            $.ajax({
                method: 'GET',
                dataType: 'JSON',
                url: apiUrl,
                success: data => {
                    var gesamtwerk = data as Gesamtwerk;
                    this.createGesamtwerkTabContent(gesamtwerk);
                },
            });
        }
    }
    /**
     * Holt die Daten für die AutorenInfo von der Api
     * Baut bei Success das AutorenInfo-Tab zusammen.
     */
    getAutorenInfoTab() {
        var apiUrl = '/Umbraco/api/ProduktdetailApi/GetAutorenInfoByProductId?id=' + this.currentProductId;
        $.ajax({
            method: 'GET',
            dataType: 'JSON',
            url: apiUrl,
            success: data => {
                var autorenInfo = data as AutorenInfo;
                this.createAutorenInfoTabContent(autorenInfo);
            },
        });
    }

    /**
     * Holt die Daten für die Rezensionen von der Api
     * Baut bei Success das Rezensionen-Tab zusammen.
     */
    getRezensionenTab() {
        var apiUrl = '/Umbraco/api/ProduktdetailApi/GetRezensionen?id=' + this.currentProductId;
        $.ajax({
            method: 'GET',
            dataType: 'JSON',
            url: apiUrl,
            success: data => {
                var rezensionen = data as RezensionenModel;
                this.createRezensionenTabContent(rezensionen);
            },
            error: () => {
                $('.accordion.ajaxSpinner_Loading').addClass('hide');
            },
        });
    }

    private createGesamtwerkTabContent(gesamtwerk: Gesamtwerk) {
        var tab = $('#tab-gesamtwerk');
        if (!this.nullOrEmpty(gesamtwerk)) {
            //Handelt es sich bei dem aktuellen Produkt nicht um das Gesamtwerk, muss es als erstes eine Verlinkung zum Gesamtwerk geben.
            if (!this.nullOrEmpty(gesamtwerk.kopfprodukt)) {
                var protKopfprodukt = $('#ProduktdetailPrototypes #GesamtwerkPrototypes p').clone();
                protKopfprodukt.find('a').attr('href', gesamtwerk.kopfprodukt.href);
                protKopfprodukt.find('.txt').html(gesamtwerk.kopfprodukt.displayName);
                tab.find('.heading').html('Weitere Titel im Gesamtwerk\n');
                tab.append(protKopfprodukt);
            }
            if (!this.nullOrEmpty(gesamtwerk.gesamtwerkTitel) && gesamtwerk.gesamtwerkTitel.length > 0) {
                //Listet die einzelnen Produkte der Reihe auf
                tab.find('.heading').html('Weitere Titel im Gesamtwerk\n');
                for (let titel of gesamtwerk.gesamtwerkTitel) {
                    var protTitel = $('#ProduktdetailPrototypes #GesamtwerkPrototypes p').clone();
                    protTitel.find('a').attr('href', titel.href);
                    protTitel.find('.txt').html(titel.displayName);
                    tab.append(protTitel);
                }
            }
        }
        this.showTabIfNotEmpty(tab, '.gesamtwerkSwitcher');
    }
    private createAutorenInfoTabContent(model: AutorenInfo) {
        var tab = $('#tab-autorenInfo');
        if (!this.nullOrEmpty(model) && !this.nullOrEmpty(model.autorenInfo) && model.autorenInfo.length > 0) {
            var heading = $('#ProduktdetailPrototypes #AutorenInfoPrototypes .heading').clone();
            if ($('#isELearning').val() == 'True') {
                heading.text('Referenten');
            }
            tab.append(heading);
            var autorList = $('#ProduktdetailPrototypes #AutorenInfoPrototypes ul').clone();
            for (let autorInfo of model.autorenInfo) {
                var listElement = $('#ProduktdetailPrototypes #AutorenInfoPrototypes li').clone();

                listElement.find('a').attr('href', autorInfo.profileLink);

                var preText = listElement.find('span');
                preText.html(autorInfo.preText);

                var preTextTitle = preText.next().find('span');
                preTextTitle.html(autorInfo.preTextTitle);

                var firstname = preTextTitle.next();
                firstname.html(autorInfo.firstName);

                var prefix = firstname.next();
                prefix.html(autorInfo.prefix);

                var name = prefix.next();

                name.html(autorInfo.name);

                const birthname = name.next();
                if (autorInfo.birthName) {
                    birthname.html(` (geb. ${autorInfo.birthName}) `);
                } else {
                    birthname.html(' ');
                }

                var postText = birthname.next();
                postText.html(autorInfo.postText);

                autorList.append(listElement);
            }
            tab.append(autorList);
        }
        this.showTabIfNotEmpty(tab, '.autorenInfoSwitcher');
    }

    private createRezensionenTabContent(model: RezensionenModel) {
        if (model.rezensionen.length > 0) {
            var tab = $('#tab-Rezesionen');
            var heading = $('#ProduktdetailPrototypes #RezensionenPrototypes .heading').clone();
            tab.append(heading);
            if (!this.nullOrEmpty(model) && !this.nullOrEmpty(model.rezensionen) && model.rezensionen.length > 0) {
                for (let rezension of model.rezensionen) {
                    var prototype = $('#ProduktdetailPrototypes #RezensionenPrototypes .rezension').clone();
                    prototype.find('.rezensionText').html(rezension.text);
                    tab.append(prototype);
                }
            }
            $('.rezensionenSwitcher').removeClass('hide');
        } else {
            $('.rezensionen-nav').addClass('hide');
        }
        //Muss hier aufgerufen werden, da es sich um das letzte Tab handelt
        this.setFocusAndShowTabs();
    }

    //Die Tabs dürfen erst angezeigt werden, wenn alles fertig geladen hat, sonst tauchen Tabs auf und verschwinden direkt wieder.
    //Muss als allerletztes aufgerufen werden
    private setFocusAndShowTabs() {
        $('#infoTabs').show();
        $('#infoTabs .nav-tabs li:visible')
            .first()
            .find('a')
            .tab('show');
        $('#infoTabs .tab-switcher-mobile:visible')
            .first()
            .find('a')
            .tab('show');
        $('.accordion.ajaxSpinner_Loading').addClass('hide');
    }

    /**
     * Wenn ein Tab leer ist wird es zusammen mit seinem switcher ausgeblendet. Der Fokus wird dannach per click() auf das nächste sichtbare Tab gelegt.
     * @param switcher
     * @param tab
     */
    private showTabIfNotEmpty(tab, switcherClass) {
        if (!this.hasNoContent(tab)) {
            $(switcherClass).removeClass('hide');
            tab.removeClass('hide');
        }
    }

    private nullOrEmpty(element) {
        return element === null || element === '' || typeof element === 'undefined';
    }

    /**
     * Überprüft ob ein Element Inhalt hat. Leerzeichen und Zeilenumbrüche werden dabei ignoriert.
     * @param element
     */
    private hasNoContent(element) {
        return !$.trim(element.html());
    }
}
