declare var dataLayer: any[];

/**
 * Tracking für Leseproben auf Produktseite
 */
export function pdfTracking(productId: number, attachement_type: string) {
    dataLayer.push({
        event: 'event',
        eventCategory: 'product data',
        eventAction: 'pdf download',
        eventLabel: `pdf_${attachement_type}_${productId}`,
        eventValue: '14.0',
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['product data', 'pdf download' + '/' + `pdf_${attachement_type}_${productId}`, 14.0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}

/**
 * Tracking für "beck-online 14-Tage-Test"
 */
export function testAboTracking(productId: number, price: number) {
    const priceValue = price * 12;
    const fixedPriceValue = priceValue.toFixed(2);
    dataLayer.push({
        event: 'event',
        eventCategory: 'product data',
        eventAction: 'click on bo button',
        eventLabel: `click_bo_${productId}`,
        eventValue: fixedPriceValue,
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: [
                'product data',
                'click on bo button' + '/' + `click_bo_${productId}`,
                Number.parseFloat(fixedPriceValue),
                'd',
            ],
            rqtype: 'hiddenpi',
        },
    });
}

/**
 * Tracking für Social Sharing auf Produktseite
 */
export function recommendationTracking(productId: number) {
    dataLayer.push({
        event: 'event',
        eventCategory: 'product data',
        eventAction: 'email_empfehlung',
        eventLabel: `email_empfehlung_${productId}`,
        eventValue: '14.0',
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['product data', 'email_empfehlung' + '/' + `email_empfehlung_${productId}`, 14.0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}

export function recensionNavClick(productId: number) {
    dataLayer.push({
        event: 'event',
        eventCategory: 'product data',
        eventAction: 'test_review-hint',
        eventLabel: `test_review-hint_${productId}`,
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['product data', 'test_review-hint' + '/' + `test_review-hint_${productId}`, 0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}

export function socialShareButtonTracking(service: string) {
    dataLayer.push({
        event: 'event',
        eventCategory: 'product data',
        eventAction: `share_${service}`,
        eventLabel: `share_${service}_pdp`,
        eventValue: '1.0',
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['product data', `share_${service}` + '/' + `share_${service}_pdp`, 1.0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}
