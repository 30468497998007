import { CombinationProducts } from "./Models/CombinationProducts";

export class CombinationProductsRenderer {

    /**
     * Holt die Kombinationsprodukte zu einem Produkt von der Api anhand ihrer Ids.
     * Die Ids werden in Hidden-Inputs zwischengespeichert
     */
    getCombinationProducts() {
        var headIds = $("#headProductIdsInput").val();
        var childIds = $("#childProductIdsInput").val();
            
        var queryString = "?" + (!this.nullOrEmpty(headIds) ? "&headProductIds=" + headIds : "") + (!this.nullOrEmpty(childIds) ? "&childProductIds=" + childIds : "");
        if (queryString !== "?") {
            var apiUrl = "/Umbraco/api/ProduktdetailApi/GetCombinationProducts" + queryString.replace("?&","?");
            $.ajax({
                method: "GET",
                dataType: 'JSON',
                url: apiUrl,
                success: data => {
                    var products = data as CombinationProducts;
                    this.createCombinationProductsContent(products);
                }
            });
        }
    }

    private createCombinationProductsContent(combinationProducts: CombinationProducts) {
        var container = $("#combinationProductsContainer");
        if (!this.nullOrEmpty(combinationProducts)) {
            var combinationProductsPrototypes = $('#ProduktdetailPrototypes #CombinationProductsPrototypes').clone();
            if (!this.nullOrEmpty(combinationProducts.headProducts)) {
                var headProductsContainer = combinationProductsPrototypes.find(".combinationProductsHead");
                for (let product of combinationProducts.headProducts) {
                    var head = $('#ProduktdetailPrototypes #CombinationProductsPrototypes').find("li").clone();
                    head.find("a").html(product.displayName).attr("href", product.href);
                    headProductsContainer.find("ul").append(head);
                }
                container.append(headProductsContainer);
            }
            if (!this.nullOrEmpty(combinationProducts.childProducts)) {
                var childProductsContainer = combinationProductsPrototypes.find(".combinationProductsChilds").clone();
                for (let product of combinationProducts.childProducts) {
                    var child = $('#ProduktdetailPrototypes #CombinationProductsPrototypes').find("li").clone();
                    child.find("a").html(product.displayName).attr("href", product.href);
                    childProductsContainer.find("ul").append(child);
                }
                container.append(childProductsContainer);
            }
        }
        $(".combinationProducts.ajaxSpinner_Loading").addClass("hide");
    }

    private nullOrEmpty(element) {
        return element === null || element === "" || typeof (element) === 'undefined';
    }
}