import { RelatedProducts } from "./Models/RelatedProducts";
import { StandardLink } from "./Models/StandardLink";
import { ShortListProduct } from "../Shared/Models/ShortListProduct";
import { getImagePath } from "../Shared/ProductHelper";

/**
 * Enthält Logiken die für das Rendern der einzelner Produktinfos
 */
export class ProductInfoRenderer {
    /**
     * Gibt es Vor- bzw. Nachfolger werden diese geladen und angezeigt
     */
    RenderRelatedProducts() {
        var predecessorIds = null;
        var descandantId: any = 0;
        var productId = $("#productGoogleTracking").data("id");
        if ($("#predecessorContainer").length) {
            predecessorIds = $("#predecessorContainer").data("id");
        }
        if ($("#descendantContainer").length) {
            descandantId = $("#descendantContainer").data("id");
        }
        if (descandantId > 0 || predecessorIds) {
            if (descandantId > 0) {
                $('.nachauflage_container').removeClass('hide'); //Spinner wird angezeigt
            }
            $.ajax({
                method: "GET",
                dataType: "JSON",
                url: `/Umbraco/api/ProduktdetailApi/GetRelatedProducts?productId=${productId}&predecessors=${predecessorIds ? predecessorIds : ""}&descendant=${descandantId}`,
                success: data => {
                    var relatedProucts = data as RelatedProducts;
                    this.createRelatedProductsHtml(relatedProucts);
                    this.fillDescendantProductBox(relatedProucts.descendantProduct);
                },
                error: () => {
                    $(".relatedproducts.ajaxSpinner_Loading").addClass("hide");
                    $('.nachauflage_container').addClass('hide');
                }
            });
        }
    }

    private createRelatedProductsHtml(relatedProducts: RelatedProducts) {
        $(".relatedproducts.ajaxSpinner_Loading").addClass("hide");
        if (relatedProducts) {
            if (relatedProducts.descendantLink) {
                this.BuildRelatedProductClone(relatedProducts.descendantLink, "Nachauflage:", $("#descendantContainer"));
            }
            if (relatedProducts.predecessorLinks && relatedProducts.predecessorLinks.length > 0) {
                var BuildRelatedProductClone = this.BuildRelatedProductClone;
                relatedProducts.predecessorLinks.forEach(function (link, index) {
                    BuildRelatedProductClone(link, index == 0 ? "Vorauflage:" : "", $("#predecessorContainer"));
                });
            }
        }
    }

    private BuildRelatedProductClone(relatedProduct: StandardLink, preText: string, container: JQuery) {
        var descendantPrototype = $("#ProduktdetailPrototypes #relatedProductPrototype p").clone();
        descendantPrototype.find("span.preTextRelatedProduct").html(preText);
        descendantPrototype.find("span.txt").html(relatedProduct.displayName);
        descendantPrototype.find("a").attr("href", relatedProduct.href);
        container.append(descendantPrototype);
    }

    private async fillDescendantProductBox(product: ShortListProduct) {
        let nachauflage_container = $('.nachauflage_container');

        if (!product) {
            nachauflage_container.addClass('hide');
            return;
        }

        nachauflage_container.find('img').attr('src', await getImagePath(product.productId,50));
        nachauflage_container.find('.authorlist').html(product.authorList);
        nachauflage_container.find('.haupttitel').html(product.titleMain);
        if (product.edition) {
            if (product.publicationYear) {
                nachauflage_container.find('.bibData').html(`${product.edition} ${product.publicationYear}`);
            } else {
                nachauflage_container.find('.bibData').html(`${product.edition}`);
            }          
        }
        else {
            nachauflage_container.find('.bibData').html(`${product.publicationYear}`);
        }
        nachauflage_container.find('.nachauflage_link').attr('href', `/${product.seoOptimizedUrlPart}/product/${product.productId}`);
        nachauflage_container.find('.ajaxSpinner_Loading').addClass('hide');
        nachauflage_container.find('.nachauflage_box').removeClass('hide');
    }
}
